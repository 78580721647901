import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Headline from "../Article/Headline";
import CheckOut from "../CheckOut/CheckOut";

class Membership extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { theme } = this.props;
    return (
      <React.Fragment>
        <div className="membership">
          <header>
            <Headline theme={theme}>Packaged Offerings</Headline>
          </header>

          <div className="bodytext">
            <h2>Fantasy Bros Premium NFL subscription</h2>
            <h3 className="pricenumber">
              {/* <span id="strikeout">$49.99</span>MID-SEASON SPECIAL: $19.99 */}
              <span id="strikeout">$79.99</span>
              <span>EARLY BIRD SPECIAL: $49.99</span>
            </h3>
            <ul>
              <li>
                Premium Ownership from UF Collective the most accurate ownership provider in the
                industry
              </li>
              <li>Optimizer</li>
              <li>
                Core plays for both featured Classic and featured (Mon, Thur, Sun) showdown slates
              </li>
              <li>Bob’s Favorite NFL bets of the week.</li>
              <li>Access to all podcasts and written content</li>
              <li>DFS Cash Game and tournament articles</li>
              <li>Weekly start/sit waiver write show advice</li>
            </ul>
          </div>
          <CheckOut theme={theme} />
        </div>

        <style jsx>{`
          .membership {
            padding: 0 ${theme.space.inset.default};
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }

          .pricenumber {
            padding: 0 ${theme.space.inset.default};
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: ${theme.color.brand.primary};
          }

          #strikeout {
            text-decoration: line-through;
            color: red;
          }

          .bodytext {
            animation-name: bodytextEntry;
            animation-duration: ${theme.time.duration.long};

            :global(h2),
            :global(h3) {
              margin: 1.5em 0 1em;
            }

            :global(h2) {
              line-height: ${theme.font.lineHeight.s};
              font-size: ${theme.font.size.l};
            }

            :global(h3) {
              font-size: ${theme.font.size.m};
              line-height: ${theme.font.lineHeight.m};
            }

            :global(p) {
              font-size: ${theme.font.size.s};
              line-height: ${theme.font.lineHeight.xxl};
              margin: 0 0 1.5em;
            }
            :global(ul) {
              list-style: circle;
              margin: 0 0 1.5em;
              padding: 0 0 0 1.5em;
            }
            :global(li) {
              margin: 0.7em 0;
              line-height: 1.5;
            }
          }

          @keyframes bodytextEntry {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }
        `}</style>
      </React.Fragment>
    );
  }
}
Membership.propTypes = {
  theme: PropTypes.object.isRequired
};

export default Membership;
