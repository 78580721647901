import React from "react";
import { ThemeContext } from "../layouts";
import Membership from "../components/Membership";
import { graphql } from "gatsby";
import Seo from "../components/Seo";
import Article from "../components/Article";
const MembershipPage = props => {
  const {
    data: {
      site: {
        siteMetadata: { facebook }
      }
    }
  } = props;
  return (
    <React.Fragment>
      <ThemeContext.Consumer>
        {theme => (
          <Article theme={theme}>
            <Membership theme={theme} />
          </Article>
        )}
      </ThemeContext.Consumer>
      <Seo facebook={facebook} />
    </React.Fragment>
  );
};

export default MembershipPage;

export const query = graphql`
  query getSiteMap {
    site {
      siteMetadata {
        facebook {
          appId
        }
      }
    }
  }
`;
