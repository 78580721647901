import React from "react";
import PropTypes from "prop-types";
import { isAuthenticated, login, getUserInfo, ROLES_URL, checkUserIsAdmin } from "../../utils/auth";
import { checkSubscribed, checkAdminUser } from "../../utils/helpers";

class CheckOut extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subscribed: null
    };
  }

  async componentDidMount() {
    const profile = typeof window !== "undefined" ? getUserInfo() : "";
    console.log(profile);
    this.stripe = window.Stripe(process.env.STRIPE_PUBLISHABLE_KEY);

    if (isAuthenticated()) {
      if (checkUserIsAdmin()) {
        this.setState({ subscribed: 1 });
      } else {
        const res = await checkSubscribed(profile.email);
        this.setState({ subscribed: res });
      }
    } else {
      this.setState({ subscribed: 0 });
    }
  }

  async redirectToCheckout(event) {
    if (!isAuthenticated()) {
      login();
    } else {
      event.preventDefault();
      const profile = typeof window !== "undefined" ? getUserInfo() : "";
      const { error } = await this.stripe.redirectToCheckout({
        items: [{ plan: process.env.STRIPE_PLAN_NFL_MIDSEASON, quantity: 1 }],
        customerEmail: profile.email,
        successUrl: process.env.STRIPE_SUCCESS_URL,
        cancelUrl: process.env.STRIPE_CANCEL_URL
      });

      if (error) {
        console.warn("Error:", error);
      }
    }
  }

  render() {
    const { theme } = this.props;

    if (this.state.subscribed === null) {
      return null;
    }

    return (
      <React.Fragment>
        {this.state.subscribed ? (
          <button className="buynow purchased" type="success" disabled>
            Purchased
          </button>
        ) : (
          <button
            className="buynow"
            type="success"
            onClick={event => this.redirectToCheckout(event)}
          >
            Buy Now
          </button>
        )}

        <style jsx>{`
          .buynow {
            background-color: ${theme.background.color.brand};
            border-radius: 5px;
            font-size: ${theme.font.size.m};
            padding: ${theme.space.s} ${theme.space.s};
            cursor: pointer;
            height: ${theme.space.xxl};
            color: ${theme.color.neutral.white};
            text-align: center;

            &:focus {
              outline-style: none;
              background: ${theme.color.brand.primary.active};
            }
          }
          .buynow.purchased {
            opacity: 0.6;
            cursor: not-allowed;
          }
        `}</style>
      </React.Fragment>
    );
  }
}

CheckOut.propTypes = {
  theme: PropTypes.object.isRequired
};

export default CheckOut;
